<template>
  <div class="game-container">
    <TigerMachineGame :bgImg="bgImg" :optionList="optionList" />
  </div>
</template>

<script>
import TigerMachineGame from "@/components/Course/GamePage/TigerMachineGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G59-tiger-machine/background-machine.svg"),

      optionList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G59-tiger-machine/chapter-05/jackpot-1.svg"),
          topDis: [-95, 0, -95, -190, -287 , -385 , -483 , -580],
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G59-tiger-machine/chapter-05/jackpot-2.svg"),
          topDis: [-190, 0, -95, -190, -287, -385 , -483 , -580],
        },
        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G59-tiger-machine/chapter-05/jackpot-3.svg"),
          topDis: [-287, 0, -95, -190, -287 , -385 , -483 , -580],
        },
      ],
    };
  },
  components: {
    TigerMachineGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











