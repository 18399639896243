<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我知道",
          isUse: false,
          content: [
            {
              pinyin: "",
              hanzi: "z",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "c",
              symbol: "",
              group: 0,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "s",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "zh",
              symbol: "",
              group: 0,
              index: 3,
              symbolType: 0,
            },
             {
              pinyin: "",
              hanzi: "ch",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
             {
              pinyin: "",
              hanzi: "sh",
              symbol: "",
              group: 0,
              index: 5,
              symbolType: 0,
            },
             {
              pinyin: "",
              hanzi: "r",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
             {
              pinyin: "fā yīn kǒu xíng",
              hanzi: "发音口型",
              symbol: "",
              group: 0,
              index: 7,
              symbolType: 0,
            },
             {
              pinyin: "fā yīn fāng fǎ",
              hanzi: "发音方法",
              symbol: "",
              group: 0,
              index: 8,
              symbolType: 0,
            },
          ],
        },
        {
          title: "我会读",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "biāozhǔn",
                hanzi: "标准",
                symbol: "",
                group: 1,
                line: 0,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "dúyīn",
                hanzi: "读音",
                symbol: "",
                group: 1,
                line: 0,
                index: 1,
                symbolType: 0,
              },
            ],
            [
              {
                pinyin: "zhǔnquède",
                hanzi: "准确地",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "dúchū",
                hanzi: "读出",
                symbol: "",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 0,
              },
              {
                pinyin: "xiāng guān",
                hanzi: "相关",
                symbol: "",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "yīn jié",
                hanzi: "音节",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
             
            ],
          ],
        },
        {
          title: "我会写",
          isUse: false,
          content: [
            // {
            //   pinyin: "ànzhào guīzé zhèngquè shūxiě pīnyīn zìmǔ",
            //   hanzi: "按照规则正确书写拼音字母",
            //   symbol: "",
            //   group: 1,
            //   line: 1,
            //   index: 0,
            //   symbolType: 0,
            // },
            {
              pinyin: "ànzhào",
              hanzi: "按照",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "guīzé ",
              hanzi: "规则",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "zhèngquè ",
              hanzi: "正确",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě ",
              hanzi: "书写",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "pīnyīn ",
              hanzi: "拼音",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "zìmǔ",
              hanzi: "字母",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>