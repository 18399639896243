<template>
  <!-- layout-container lesson 15 -->
  <div class="lesson39-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import OpeningSongPage from "./4_OpeningSongPage.vue";
import IntroductionPage from "./5_Introduction.vue";
import StoryPageOne from "./6_StoryPageOne.vue";
import RecognitionPage_z from "./7_RecognitionPage_z.vue";
import RecognitionPage_c from "./8_RecognitionPage_c.vue";
import RecognitionPage_s from "./9_RecognitionPage_s.vue";
import PracticePageOne from "./10_PracticePageOne.vue";
import StoryPageTwo from "./11_StoryPageTwo.vue";
import RecognitionPage_zh from "./12_RecognitionPage_zh.vue";
import RecognitionPage_ch from "./13_RecognitionPage_ch.vue";
import RecognitionPage_sh from "./14_RecognitionPage_sh.vue";
import RecognitionPage_r from "./15_RecognitionPage_r.vue";
import BasketballGame from "./16_BasketballGame.vue";
import PracticePageTwo from "./17_PracticePageTwo.vue";
import StoryPageThree from "./18_StoryPageThree.vue";
import PracticePageThree from "./19_PracticePageThree.vue";
import RobotJumpGame from "./20_RobotJumpGame.vue";
import PracticePageFour from "./21_PracticePageFour.vue";
import WritingPage from "./22_WritingPage.vue";
import WritingPage_1 from "./22_WritingPage_1.vue";
import PuzzleGame from "./23_PuzzleGame.vue";
import PinyinSummary from "./24_PinyinSummary.vue";
import RankMapPageTwo from "./25_RankMapPageTwo.vue";
import ByePage from "./26_ByePage.vue";
export default {
  name: "Lesson39",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    OpeningSongPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPage_z,
    RecognitionPage_c,
    RecognitionPage_s,
    PracticePageOne,
    StoryPageTwo,
    RecognitionPage_zh,
    RecognitionPage_ch,
    RecognitionPage_sh,
    RecognitionPage_r,
    PracticePageTwo,
    BasketballGame,
    StoryPageThree,
    PracticePageThree,
    RobotJumpGame,
    PracticePageFour,
    WritingPage,
    WritingPage_1,
    PuzzleGame,
    PinyinSummary,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        OpeningSongPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPage_z,
        RecognitionPage_c,
        RecognitionPage_s,
        PracticePageOne,
        StoryPageTwo,
        RecognitionPage_zh,
        RecognitionPage_ch,
        RecognitionPage_sh,
        RecognitionPage_r,
        BasketballGame,
        PracticePageTwo,
        StoryPageThree,
        PracticePageThree,
        RobotJumpGame,
        PracticePageFour,
        WritingPage,
        WritingPage_1,
        PuzzleGame,
        PinyinSummary,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson39-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>
