<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList" :menuSize="`small`" :menuItems="7"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/1-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/1-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/1-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/2-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/2-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/2-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/3-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/3-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/3-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/4-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/4-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/4-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/5-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/5-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/5-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/6-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/6-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/6-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/7-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/7-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/7-3.svg"),
            showObj: true,
          },
        ],
        [
          
        ],
      ],

      answerList: [
        {
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/suo3.mp3"),
          id: 1,
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/1-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/1-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/1-puzzle.svg"),
        },
        {
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/shu3.mp3"),
          id: 1,
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/2-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/2-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/2-puzzle.svg"),
        },
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/zhu2.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/3-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/3-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/3-puzzle.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/che1.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/4-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/4-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/4-puzzle.svg"),
        },
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ru4.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/5-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/5-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/5-puzzle.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/zu2.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/6-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/6-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/6-puzzle.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ca1.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/7-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/7-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-39/7-puzzle.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>
