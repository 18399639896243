<template>
  <div class="spaeaking-page-two-container layout-border">
    <div class="spaeking-page-two-box"></div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  data() {
    return {
      hoverClass: 1,
      numBers: 1,
      fontSizeSum: 1,
      hoverClassOne: 1,
      numBersOne: 1,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true,
          },
        ],
      },
    };
  },
  methods: {
    mouseover() {
      this.hoverClassOne = 2;
    },
    mouseleave() {
      this.hoverClassOne = 1;
    },
    nextPage() {
      // this.numBers = type + 1;
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.spaeaking-page-two-container {
  .spaeking-page-two-box {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f5f9ff;
    background-image: url("../../../../../assets/img/19-Book-review/lesson-39-start.svg");
    background-size: cover;
    border-radius: 58px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .spaeaking-page-two-content {
      width: 90%;
      @media screen and (max-width: 1500px) {
        // laptop
        width: 75%;
      }
      @media screen and (max-width: 1300px) {
        // ipad
        width: 95%;
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>
