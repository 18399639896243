`<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :pinyinList="pinyinList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: ["zh", "ch", "sh", "r"], //ü
      strokeTimesList: [1, 1, 1, 1],
      pinyinImgList:[
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-05/pinyin-zh-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-05/chapter-05-zh.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-05/pinyin-ch-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-05/chapter-05-ch.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-05/pinyin-sh-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-05/chapter-05-sh.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-05/pinyin-r-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-05/chapter-05-r.gif'),
        },
        
        
      ],
      buttonList: [
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-zh.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ch.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-sh.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-r.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        
      ],
    };
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
