<template>
  <div class="rank-map-page-one">
    <PinyinSummary :pinyinImgList="pinyinImgList" :bgImg="bgImg" :lesson="lesson"/>
  </div>
</template>

<script>
import PinyinSummary from "@/components/Course/CoursePage/PinyinSummary";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/19-Book-review/lesson-39-background.svg"),
      pinyinString: ["z", "c", "s", "zh","ch","sh","r"],
      lesson:39
    };
  },
  computed: {
    pinyinImgList() {
      let imgArr = [];
      for (let i = 1; i <= 7; i++) {
        const element = require(`@/assets/img/19-Book-review/lesson-39-review-${
          this.pinyinString[i - 1]
        }.svg`);
        imgArr.push(element);
      }
      return imgArr;
    },
  },
  components: {
    PinyinSummary,
  },
};
</script>

<style lang="scss" scoped>
.rank-map-page-one {
  width: 100%;
  height: 100%;
}
</style>