<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="5"/>
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Pīnyīn (wǔ) z c s zh ch sh r",
        hanzi: "拼音 (五) z c s zh ch sh r",
        english: "Pinyin 5 | z c s zh ch sh r",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-39-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>